import { pushEvent } from "@wbly/common";
import type { W, DataLayer } from "@wbly/common";

export const trackNewsletterSignUp = (success: string) => {
  pushEvent(
    (window as W)?.dataLayer as DataLayer[],
    "",
    `sign_up_newsletter`,
    `${success}` && "Completed"
  );
};
